<template>
    <div>
    
      <v-app-bar id="app-header" v-if="$route.meta.showHeader" class="app-header-color" clipped-right clipped-left app>

      <v-img
        class="mx-2 mb-2 mouse-pointer"
        src="@/assets/Proteus.png"
        max-height="100"
        max-width="40"
        :loading="loading"
        @click="goToPage('/')"
      ></v-img>

      <v-toolbar-title
      class="white--text"
      style="padding-top: 10px !important;cursor:pointer;"
      @click="goToPage('/')"
      >
        WATER QUALITY MONITORING SYSTEM
      </v-toolbar-title>


      <v-spacer></v-spacer>

      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mobile && adminCanUse && this.btnSysAdmin==true" dark @click.stop="SysAdmin = !SysAdmin">
        <v-icon>mdi-account-tie</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mobile" dark @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      

      <div class="text-center"  v-if="$vuetify.breakpoint.lgAndUp">
        <!-- Button Home/Map -->
        <v-menu
        origin="center center"
        transition="scale-transition"
        offset-y
        >

        <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                color="grey"
                icon
                rounded
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon color="white" role="img" @click="goToPage('/')">mdi-map-legend</v-icon>
                </v-btn>
              </template>
              <span>Full Map</span>
            </v-tooltip>
          </template>

        </v-menu>


        <!-- Button Alert -->
        <v-menu
        origin="center"
        transition="scale-transition"
        offset-y
        class="mt-16"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                color="grey"
                icon
                rounded
                v-bind="attrs"
                v-on="{ ...tooltip, ...on }"
                >
                  <v-icon color="white" dense>mdi-bell</v-icon>
                </v-btn>
              </template>
              <span>Alert</span>
            </v-tooltip>
          </template>
          <v-list class="list-inChild" flat>
            <v-subheader class="downStyleSarawak">Alert</v-subheader>
            <v-list-item-group
              color="primary"
              class="bbb"
            >
            <v-list-item
              v-for="(item, index) in alertItems"
              :key="index"
              class="ccc"
              :to="item.url"
            >
              <v-list-item-title v-text="item.title" ></v-list-item-title>
            </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <!-- Button Report -->
        <v-menu
        origin="center center"
        transition="scale-transition"
        offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                color="grey"
                icon
                rounded
                v-bind="attrs"
                v-on="{ ...tooltip, ...on }"
                >
                  <v-icon color="white" role="img">mdi-file-document-outline</v-icon>
                </v-btn>
              </template>
              <span>Report</span>
            </v-tooltip>
          </template>

          <v-list class="list-inChild">
            <v-subheader class="downStyleSarawak">Water Quality Report</v-subheader>
              <v-list-item-group
                color="primary"
              >
                <v-list-item
                  v-for="(item, index) in wqReportItems"
                  :key="index"
                  :to="item.url"
                >
                  <v-list-item-title disabled v-text="item.title" ></v-list-item-title>
                </v-list-item>
              </v-list-item-group>
          </v-list>

          <v-list class="list-inChild">
            <v-subheader class="downStyleSarawak">Operational</v-subheader>
            <v-list-item-group
              color="primary"
            >
            <v-list-item
              v-for="(item, index) in operationReportItems"
              :key="index"
              :to="item.url"
            >
              <v-list-item-title v-if="item.title != 'QAQC 2sec Level'" v-text="item.title" ></v-list-item-title>
              <v-list-item-title v-if="item.title == 'QAQC 2sec Level'">
                {{ item.title.split("sec")[0] }}<sup>nd</sup> <span class="ml-0">{{ item.title.split("sec")[1] }}</span>
              </v-list-item-title>

            </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-list class="list-inChild">
            <v-subheader class="downStyleSarawak">CMS Reporting</v-subheader>
            <v-list-item-group
              color="primary"
            >
            <v-list-item
              v-for="(item, index) in cmsReportItems"
              :key="index"
              :to="item.url"
            >
              <v-list-item-title disabled v-text="item.title" ></v-list-item-title>

            </v-list-item>
            </v-list-item-group>
          </v-list>

        </v-menu>
        
        <!-- Button System Admin -->
        <v-menu
        origin="center center"
        transition="scale-transition"
        offset-y
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                color="grey"
                icon
                rounded
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon color="white" role="img">mdi-account</v-icon>
                </v-btn>
              </template>
              <span>Profile</span>
            </v-tooltip>
          </template>
          <v-list class="list-inChild">
            <v-subheader class="downStyleSarawak">Profile</v-subheader>
            <v-list-item-group
              color="primary"
            >
            <!-- <v-list-item
              v-for="(item, index) in userItems"
              :key="index"
              :to="item.url"
            >
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item> -->
            <v-list-item
            @click="goToPage('/userProfile/userProfile')"
            >
              <v-list-item-title>User Profile</v-list-item-title>
            </v-list-item>
            <v-list-item
            @click="goToPage('/systemAdmin/userManagement/userList')"
            v-if="adminCanUse"
            >
              <v-list-item-title>System Admin</v-list-item-title>
            </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <!-- Button Logout -->
        <v-menu
        origin="center center"
        transition="scale-transition"
        offset-y
        >

        <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                color="grey"
                icon
                rounded
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon color="white" role="img" @click="signOut">mdi-logout</v-icon>
                </v-btn>
              </template>
              <span>Log Out</span>
            </v-tooltip>
          </template>

        </v-menu>

      </div>

    </v-app-bar>

    
    <!-- mobile view list -->
    <v-navigation-drawer
      v-model="drawer"
      app
      right
      clipped
      height="220px"
      bottom
      v-if="$vuetify.breakpoint.mdAndDown"
      
    >
      <v-list
        nav
        dense
      >
        <v-list-group
        :value="false"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-title>Alert</v-list-item-title>
        </template>

          <v-list-item
            v-for="(item, index) in alertItems"
            :key="index"
            link
          >
            <v-list-item-title @click="goToPage(item.url);hideSystemAdmin();" v-text="item.title"></v-list-item-title>

          </v-list-item>
        </v-list-group>

        <v-list-group
        :value="false"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-title>Water Quality Report</v-list-item-title>
        </template>

          <v-list-item
            v-for="(item, index) in wqReportItems"
            :key="index"
            link
          >
            <v-list-item-title @click="goToPage(item.url);hideSystemAdmin();" v-text="item.title"></v-list-item-title>

          </v-list-item>
        </v-list-group>

        <v-list-group
        :value="false"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-title>Operational Report</v-list-item-title>
        </template>

          <v-list-item
            v-for="(item, index) in operationReportItems"
            :key="index"
            link
          >
            <v-list-item-title @click="goToPage(item.url);hideSystemAdmin();" v-text="item.title"></v-list-item-title>

          </v-list-item>
        </v-list-group>

        <v-list-group
        :value="false"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-title>Profile</v-list-item-title>
        </template>

          <v-list-item
            v-for="(item, index) in userItems"
            :key="index"
            link
          >
            <v-list-item-title @click="goToPage(item.url);openSystemAdmin();" v-text="item.title"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item>
          <v-list-item-title @click="signOut;hideSystemAdmin();">Sign Out</v-list-item-title>
        </v-list-item>


      </v-list>
    </v-navigation-drawer>


    <!-- Menu System Admin in mobile view -->
    <v-navigation-drawer
      v-model="SysAdmin"
      app
      right
      clipped
      height="auto"
      bottom
      v-if="$vuetify.breakpoint.mdAndDown"
      
    >
      <v-list
      nav
      dense
      >

        <v-list-group
        :value="false"
        no-action
        >
        
          <template v-slot:activator>
            <v-list-item-title>
              <v-icon>mdi-account</v-icon>
              User Management
            </v-list-item-title>
          </template>
          <v-list-item
          v-for="(item, index) in users"
          :key="index"
          link
          >
            <v-icon v-text="item.icon"></v-icon>
            <v-list-item-title @click="goToPage(item.url)" v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
        :value="false"
        no-action
        >
        
          <template v-slot:activator>
            <v-list-item-title>
              <v-icon>mdi-store</v-icon>
              Station Management
            </v-list-item-title>
          </template>
          <v-list-item
          v-for="(item, index) in stations"
          :key="index"
          link
          >
            <v-icon v-text="item.icon"></v-icon>
            <v-list-item-title @click="goToPage(item.url)" v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
        :value="false"
        no-action
        >
        
          <template v-slot:activator>
            <v-list-item-title>
              <v-icon>mdi-map-marker</v-icon>
              Point Of Interest Management
            </v-list-item-title>
          </template>
          <v-list-item
          v-for="(item, index) in interest"
          :key="index"
          link
          >
            <v-icon v-text="item.icon"></v-icon>
            <v-list-item-title @click="goToPage(item.url)" v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
        :value="false"
        no-action
        >
        
          <template v-slot:activator>
            <v-list-item-title>
              <v-icon>mdi-flask-outline</v-icon>
              Parameter Management
            </v-list-item-title>
          </template>
          <v-list-item
          v-for="(item, index) in parameters"
          :key="index"
          link
          >
            <v-icon v-text="item.icon"></v-icon>
            <v-list-item-title @click="goToPage(item.url)" v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
        :value="false"
        no-action
        >
        
          <template v-slot:activator>
            <v-list-item-title>
              <v-icon>mdi-database-cog</v-icon>
              Manual Data Update
            </v-list-item-title>
          </template>
          <v-list-item
          v-for="(item, index) in data"
          :key="index"
          link
          >
            <v-icon v-text="item.icon"></v-icon>
            <v-list-item-title @click="goToPage(item.url)" v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
        :value="false"
        no-action
        >
        
          <template v-slot:activator>
            <v-list-item-title>
              <v-icon>mdi-air-filter</v-icon>
              Calibration Management
            </v-list-item-title>
          </template>
          <v-list-item
          v-for="(item, index) in calibration"
          :key="index"
          link
          >
            <v-icon v-text="item.icon"></v-icon>
            <v-list-item-title @click="goToPage(item.url)" v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
        :value="false"
        no-action
        >
        
          <template v-slot:activator>
            <v-list-item-title>
              <v-icon>mdi-wrench-clock</v-icon>
              Maintenance Management
            </v-list-item-title>
          </template>
          <v-list-item
          v-for="(item, index) in maintenance"
          :key="index"
          link
          >
            <v-icon v-text="item.icon"></v-icon>
            <v-list-item-title @click="goToPage(item.url)" v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>

      </v-list>
    </v-navigation-drawer>

    </div>
</template>

<script>
export default {
    data () {
      return {
        drawer: false,
        group: null,
        alertItems: [
            { title:'Exceedance' , url: "/Alert/Exceedance"},
            { title:'Data Transmission Status' , url: "/Alert/Transmission"},
            { title:'Exceedance by Parameter' , url: "/Alert/ExceedanceByParameter"},
        ],
        wqReportItems: [
            { title:'Daily Report' , url: "/Report/DailyReport"},
            { title:'Monthly Report' , url: "/Report/MonthlyReport"},
            { title:'Yearly Report' , url: "/Report/YearlyReport"},
            { title:'Query Options' , url: "/Report/QueryOptions"},
            { title:'Raw Data' , url: "/Report/RawData"},
        ],
        operationReportItems: [
            { title:'Maintenance', url: "/Operational/Maintenance"},
            { title:'Calibration', url: "/Operational/Calibration"},
            { title:'QAQC 2sec Level', url: "/Operational/QAQCSecLevel"},
            { title:'Validation', url: "/Operational/Validation"},
        ],
        cmsReportItems: [
            { title:'Reporting', url: "/CMSReporting/Reporting"},
        ],
        userItems: [
            { title:'User Profile', url: "/userProfile/userProfile" },
            { title:'System Admin', url: "/systemAdmin/userManagement/userList" },
        ],
        logOut: {
          title:'Log Out'
        },



        // Mobile System Admin Version
        btnSysAdmin: false,
        SysAdmin: false,
        users: [
          { 
            name: "User List", 
            icon: "mdi-format-list-bulleted", 
            url: '/systemAdmin/userManagement/userList' 
          },

          { 
            name: "Add User",
            icon: "mdi-account-plus", 
            url:  '/systemAdmin/userManagement/addUser' 
          }
        ],

        stations: [
          { 
            name: "Station List", 
            icon: "mdi-format-list-bulleted", 
            url: '/systemAdmin/stationManagement/stationList' 
          },
          { 
            name: "Add Station", 
            icon: "mdi-store-24-hour", 
            url: '/systemAdmin/stationManagement/addStation' 
          }
        ],

        interest: [
          { 
            name: "List of Point Interest", 
            icon: "mdi-format-list-bulleted", 
            url: '/systemAdmin/pointOfInterest/interestList' 
          },
          { 
            name: "Add Point Interest", 
            icon: "mdi-plus", 
            url: '/systemAdmin/pointOfInterest/addInterest' 
          }
        ],

        parameters: [
          { 
            name: "Parameter List", 
            icon: "mdi-format-list-bulleted", 
            url: '/systemAdmin/parameterManagement/parameterList' 
          },
          { 
            name: "Set Parameter", 
            icon: "mdi-cup-water", 
            url: '/systemAdmin/parameterManagement/addParameter' 
          },
          { 
            name: "Set Min Max", 
            icon: "mdi-tune", 
            url: '/systemAdmin/parameterManagement/setMinMax' 
          }
        ],

        data: [
          { 
            name: "Data Update", 
            icon: "mdi-database-refresh-outline", 
            url: '/systemAdmin/manualDataUpdate/dataUpdate' 
          }
        ],

        maintenance: [
          { 
            name: "Data Entry", 
            icon: "mdi-database-import-outline", 
            url: '/systemAdmin/maintenanceManagement/dataEntry' 
          },
          { 
            name: "On Going", 
            icon: "mdi-database-sync-outline", 
            url: '/systemAdmin/maintenanceManagement/OnGoing' 
          },
          { 
            name: "Historical Summary", 
            icon: "mdi-database-clock-outline", 
            url: '/systemAdmin/maintenanceManagement/Summary' 
          },
        ],

        calibration: [
          { 
            name: "Data Entry", 
            icon: "mdi-database-import-outline", 
            url: '/systemAdmin/calibrationManagement/dataEntry' 
          },
          { 
            name: "On Going", 
            icon: "mdi-database-sync-outline", 
            url: '/systemAdmin/calibrationManagement/OnGoing' 
          },
          { 
            name: "Historical Summary", 
            icon: "mdi-database-clock-outline", 
            url: '/systemAdmin/calibrationManagement/Summary' 
          },
        ],

          
      }
    },

    methods: {

      loading() {
        return this.$store.getters.loading
      },

      signOut() {
        this.$store.dispatch('signOut')
      },

      hideSystemAdmin(){
        window.SysAdminFocus = false;
        this.btnSysAdmin = false;
      },

      openSystemAdmin(){
        window.SysAdminFocus = true;
        this.btnSysAdmin = true;
      },



     openPdf(){
      // window.open("file/manual.pdf","_blank");
      window.open("SEB-USER MANUAL v01 ED.pdf","_blank");
     }

      
    },

    computed: {
      user() {
          return this.$store.getters.user
      },
    },

    mounted(){
      if (window.SysAdminFocus == true) {
        this.btnSysAdmin = true;
      }
      else{
        this.btnSysAdmin = false;
      }
    }
}
</script>

<style lang="scss" scoped>

  @import '~scss/main';

  .app-header-color {
    background-image: linear-gradient(to left,#0081BA, #02B3C3);
  }
  

  .list-inChild{
    padding: 0px;
  }

  .v-menu__content{
    top: 70px !important;
    max-width: 95% !important; 
  }

  .mouse-pointer {
    cursor: pointer;
  }

</style>

