<template>
  <v-footer
    id="app-footer"
    padless
    height="150%"
    v-if="$route.meta.showFooter"
    class="app-footer-color"
  >
    <!-- <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        v-for="link in links"
        :key="link"
        color="white"
        text
        rounded
        class="my-2"
      >
        {{ link }}
      </v-btn>
    </v-row> -->

    <v-container>

      <v-row>

      <v-col class="ml-5">
        <v-row>
          <small class="white--text">Terms & Conditions</small>
        </v-row>
        <v-row>
          <small class="white--text">Privacy Policy</small>
        </v-row>
        <v-row>
          <small class="white--text">Guides & Booklets</small>
        </v-row>
      </v-col>
        
      <v-col>
        <v-row>
          <small class="white--text">For General Enquiries, Please Contact</small>
        </v-row>
        <v-row>
          <small class="white--text">Operating Hours:</small>
        </v-row>
        <v-row>
          <small class="white--text">8.00 AM - 5.00 PM daily</small>
        </v-row>
        <v-row>
          <small class="white--text">
            Email us at <span class="text-warning">admin@scienotech.com.my</span>
          </small>
        </v-row>
      </v-col>

      <v-col class="mr-5">
        <v-row>
          <small class="white--text">Follow Us On</small>
        </v-row>
        <v-row>
          <small class="white--text">Download WQMS App</small>
        </v-row>
        <v-row>
          <small class="white--text">Browser Compatibility</small>
        </v-row>
      </v-col>

      <v-col>
        <v-row>
          <small class="white--text">
            <v-icon dense class="white--text mr-2">mdi-facebook</v-icon>
            <v-icon dense class="white--text mr-2">mdi-instagram</v-icon>
            <v-icon dense class="white--text">mdi-twitter</v-icon>
          </small>
        </v-row>
        <v-row>
          <v-icon dense class="white--text mr-2">mdi-android</v-icon>
          <v-icon dense class="white--text mr-2">mdi-apple</v-icon>
        </v-row>
        <v-row>
          <v-icon dense class="white--text mr-2">mdi-google-chrome</v-icon>
          <v-icon dense class="white--text mr-2">mdi-firefox</v-icon>
          <v-icon dense class="white--text mr-2">mdi-microsoft-internet-explorer</v-icon>
        </v-row>
      </v-col>

    </v-row>

    </v-container>

  </v-footer>

</template>

<script>
  export default {
    data: () => ({
      links: [
        'Home',
        'About Us',
        'Team',
        'Services',
        'Blog',
        'Contact Us',
      ],
    }),
  }
</script>

<style lang="scss" scoped>

.app-footer-color {
  background-image: linear-gradient(to left,#0081BA, #02B3C3);
}

.text-warning {
    color: #ffc107!important;
}

</style>