<template>
  <v-app>
    <!-- app header -->
    <app-header></app-header>

    <!-- left panel system admin-->
    <left-panel v-if="$route.meta.showLeftPanel"></left-panel>

    <!-- content -->
    <v-main>
      <router-view :key="$route.path"></router-view>
    </v-main>

    <!-- app footer -->
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import LeftPanelSysAdmin from "@/components/SysAdmin/LeftPanelSysAdmin";
import * as easings from 'vuetify/lib/services/goto/easing-patterns'
export default {
  name: "App",
  components: {
    "app-header": AppHeader,
    "app-footer": AppFooter,
    "left-panel": LeftPanelSysAdmin,
  },

  data: () => ({}),

  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "~scss/main";
</style>
