export default {
    data() {
        return {}
    },
    methods: {
        goToPage(url){
            this.$router.push({ path: url }).catch(()=>{});
        },
        reloadPage(){
            window.location.reload();
        },
        convert12Hours(time) {

            if (time != null && time != "") {
                var H = +time.substr(0, 2);
                var h = H % 12 || 12;
                var ampm = (H < 12 || H === 24) ? "AM" : "PM";
                time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
                return time;
            }
            
        },

        convert24Hours(time12h) {
            const [time, modifier] = time12h.split(' ');

            let [hours, minutes, seconds] = time.split(':');

            if (hours === '12') {
                hours = '00';
            }

            if (modifier === 'PM') {
                hours = parseInt(hours, 10) + 12;
            }

            // console.log(hours);

            if (hours > 0  && hours < 10) {
                hours = "0"+hours
            }

            // time12h = hours + ":" + minutes + ":" + seconds

            // return time12h;
            return `${hours}:${minutes}:${seconds}`;
        },

        Test() {

            let time12h = "12:00:00 PM"

            const [time, modifier] = time12h.split(' ');

            let [hours, minutes, seconds] = time.split(':');

            if (hours === '12') {
                hours = '00';
            }

            if (modifier === 'PM') {
                hours = parseInt(hours, 10) + 12;
            }

            // if (hours == 1) {
            //     hours = "0"+hours
            // }
            // hours = "0"+hours;

            // console.log(hours);

            if (hours > 0  && hours < 10) {
                hours = "0"+hours
            }

            time12h = hours + ":" + minutes + ":" + seconds

            console.log(time12h);
        },

        convertDateFormat(date){

            // console.log(date);

            if (date != null && date != "") {
                var dd = date.split("-")[2];
                var mm = date.split("-")[1];
                var yyyy = date.split("-")[0];
                date = dd + "/" + mm + "/" + yyyy;
                return date;
            }
            
        },

        convertToDbDt(date){
            var dd = date.split("/")[0];
            var mm = date.split("/")[1];
            var yyyy = date.split("/")[2];
            date = yyyy + "-" + mm + "-" + dd;
            
            return date;
        },

        specialExcel(theadjson, tbodyjson){
            console.log(theadjson, tbodyjson);

            var newarr = [];
            
            
            for(let i in tbodyjson){

                let emptyjson = {};

                for(let j in theadjson){
                    let str = theadjson[j].text;
                    str = str.split(' (')[str.split(' (').length-1].replace(')','');

                    // console.log(theadjson[j].value);

                    if (theadjson[j].value != "time" && theadjson[j].value != "datetime_a"
                    && theadjson[j].value != "STATION_ID" && theadjson[j].value != "location"
                    && theadjson[j].value != "DATETIME" && theadjson[j].value != "stationid"
                    && theadjson[j].value != "datetime" && theadjson[j].value != "station_a") {
                        emptyjson[[theadjson[j].value] + ' (' + str + ')' ] = tbodyjson[i][theadjson[j].value];
                    }
                    else{
                        emptyjson[[theadjson[j].value]] = tbodyjson[i][theadjson[j].value];
                    }

                    
                }
                newarr.push(emptyjson);
            }

            // document.getElementById('output').textContent = JSON.stringify(newarr, null, 2);
            
            return newarr;
        }
    },
    mounted(){
        // this.Test();
    },
    computed: {
        adminCanUse() {
            return this.$store.getters.userRole === 'admin' ? true : false
        },
        operationCanUse() {
            return this.$store.getters.userRole === 'operation' ? true : false
        },
        qaqcCanUse() {
            return this.$store.getters.userRole === 'qaqc' ? true : false
        },
        userCanUse() {
            return this.$store.getters.userRole === 'general' ? true : false
        },

        
    },
}